<div class="container">
  <div class="row mt-5 mb-3">
    <div class="col-12">
      <div class="card border-primary">
        <div class="card-header text-center text-info text-uppercase">
          Sign Up Please
        </div>

        <div class="card-body">
          <form (ngSubmit)="onSubmit(f)" #f="ngForm">
            <div>
              <!-- image picker -->
              <div class="text-center">
                <label for="imagepicker" class="">
                  <img [src]="picture" width="100" height="100" alt="" class="profile" />
                </label>

                <input type="file" name="image" id="imagepicker" accept="image/*" multiple="{false}" class="hidden"
                  hidden (change)="uploadFile($event)" />
              </div>

              <div [hidden]="!uploadPercent || uploadPercent==100" class="text-white text-center">
                uploading
                {{uploadPercent | number: '1.0-0'}} % of 100%
              </div>
              <div *ngIf="uploadPercent==100" class="text-success text-uppercase text-center mb-3">
                Upload finished
              </div>
            </div>

            <div class="form-group row">
              <label for="name" class="col-sm-3 col-form-label">Name</label>
              <div class="col-sm-9">
                <input type="name" class="form-control" id="name" name="name" placeholder="Enter Name" required
                  ngModel />
              </div>
            </div>

            <div class="form-group row">
              <label for="email" class="col-sm-3 col-form-label">Email</label>
              <div class="col-sm-9">
                <input type="email" class="form-control" id="email" name="email" placeholder="Enter Email" required
                  ngModel />
              </div>
            </div>

            <div class="form-group row">
              <label for="password" class="col-sm-3 col-form-label">Password</label>
              <div class="col-sm-9">
                <input type="password" class="form-control" id="password" name="password" placeholder="Enter password"
                  required ngModel />
                <small id="emailHelp" class="form-text text-muted">Minimum length of the password should be 6 or
                  more</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="username" class="col-sm-3 col-form-label">Instagram name</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" id="username" name="username"
                  placeholder="Enter your handle of instagram" required ngModel />
              </div>
            </div>

            <div class="form-group row">
              <label for="country" class="col-sm-3 col-form-label">Country</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" id="country" name="country" placeholder="Your Country" required
                  ngModel />
              </div>
            </div>

            <div class="form-group row">
              <label for="bio" class="col-sm-3 col-form-label">bio</label>
              <div class="col-sm-9">
                <textarea type="text" class="form-control" id="bio" name="bio" placeholder="some bio about you" required
                  ngModel></textarea>
              </div>
            </div>

            <div class="text-center">
              <button [disabled]="!f.form.valid" class="btn btn-primary" type="submit">
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
