import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

// form
import { NgForm } from '@angular/forms';

// service
import { AuthService } from 'src/app/services/auth.service';

//rxjs
import { finalize } from 'rxjs/operators'; // when something completes then what to do

//firebase
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';

// browser image resizer
import { readAndCompressImage } from 'browser-image-resizer';

// upload image config file
import { imageConfig } from 'src/utils/config';

//uuid
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.css'],
})
export class AddPostComponent implements OnInit {
  locationName: string;
  description: string;
  picture: string = null;
  user = null;
  uploadPercent: number = null;

  constructor(
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private toastr: ToastrService,
    private auth: AuthService,
    private router: Router
  ) {
    auth.getUser().subscribe((user) => {
      this.db
        .object(`/users/${user.uid}`)
        .valueChanges()
        .subscribe((user) => {
          // updated user after any changes
          this.user = user;
        });
    });
  }

  ngOnInit(): void {}

  onSubmit = () => {
    const uid = uuidv4();

    // is post is not present then it will create or else it will go inside
    this.db
      .object(`/posts/${uid}`)
      .set({
        id: uid,
        locationName: this.locationName,
        description: this.description,
        picture: this.picture,
        by: this.user.name,
        instaId: this.user.username,
        date: Date.now(),
      })
      .then(() => {
        this.toastr.success('Post Added Successfully');
        this.router.navigateByUrl('/');
      })
      .catch((err) => {
        this.toastr.error('OOpppppsss');
      });
  };

  uploadFile = async (event) => {
    const file = event.target.files[0];

    let resizedImage = await readAndCompressImage(file, imageConfig);

    const filePath = file.name;
    const fileRef = this.storage.ref(filePath);

    const task = this.storage.upload(filePath, resizedImage);

    task.percentageChanges().subscribe((percentage) => {
      this.uploadPercent = percentage;
    });

    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((url) => {
            this.picture = url;
            this.toastr.success('Image Upload Successfull');
          });
        })
      )
      .subscribe();
  };
}
