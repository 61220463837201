<nav class="navbar navbar-expand-lg navbar-light bg-nav">
  <a class="navbar-brand" [routerLink]="['/']">
    <img src="../../../assets/img.png" width="30" height="30" class="d-inline-block align-top" alt="" />
    TravelGram
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav ml-auto">
      <!-- if the user is present then printing email -->
      <div *ngIf="email" class="navbar-text float-left">
        {{ email }}
      </div>
      <a [routerLink]="['/']" routerLinkActive="active" class="nav-item nav-link" [routerLinkActiveOptions]="{exact:
true}">Home</a>
      <!-- conditionally rendering links  -->
      <a *ngIf="!email" routerLinkActive="active" [routerLink]="['/signin']" class="nav-item nav-link p-2">Sign In</a>
      <a *ngIf="!email" routerLinkActive="active" [routerLink]="['/signup']" class="nav-item nav-link p-2">Sign Up</a>

      <a *ngIf="email" (click)="handleSignOut()" class="nav-item nav-link p-2" style="cursor: pointer;">Log Out</a>

      <a *ngIf="email" [routerLink]="['/addpost']" class="nav-item nav-link btn btn-primary btn-sm text-white p-2">
        Post Your Story</a>
    </div>
  </div>
</nav>
