<div class="card mb-3 horizontal-display">
  <div class="card-body d-flex justify-content-center align-items-center">
    <img [src]="user.picture" width="60" height="60" class="rounded-circle ml-2" alt="">
  </div>
  <div class="card-footer">
    <p class="card-title" style="font-weight: bold;">{{user.name | uppercase}}</p>
    <p class="card-text">{{user.bio}}</p>
  </div>
</div>

<!-- html for mobile device -->
