import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  users = [];
  posts = [];

  isLoading = false;
  constructor(private db: AngularFireDatabase, private toastr: ToastrService) {
    this.isLoading = true; // still no connection with db

    // getting all users from DB
    db.object('/users')
      .valueChanges()
      .subscribe((obj) => {
        if (obj) {
          this.users = Object.values(obj); // coverting obj to arrays
          console.log('homecomp', this.users);
          this.isLoading = false; // connection with db done
        } else {
          this.toastr.error('No User Found');
          this.users = [];
          this.isLoading = false;
        }
      });

    // grab all post from firebase
    db.object('/posts')
      .valueChanges()
      .subscribe((obj) => {
        if (obj) {
          this.posts = Object.values(obj).sort((a, b) => b.date - a.date);
          console.log('homecomp', this.posts);
          this.isLoading = false;
        } else {
          this.toastr.error('No Post to Display');
          this.posts = [];
          this.isLoading = false;
        }
      });
  }

  ngOnInit(): void {}
}
