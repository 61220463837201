import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

// form
import { NgForm } from '@angular/forms';

// service
import { AuthService } from 'src/app/services/auth.service';

//rxjs
import { finalize } from 'rxjs/operators'; // when something completes then what to do

//firebase
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';

// browser image resizer
import { readAndCompressImage } from 'browser-image-resizer';

// upload image config file
import { imageConfig } from 'src/utils/config';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  picture: string =
    'https://lh3.googleusercontent.com/proxy/fLzlqVW_F3WWJeXW5yBMyK3kltuI9w4Z96FudbiwLGGyxqqiDu8_2TbZJnZjtX2b7RpZxLraH3RBKtP6lUleb-yOEI3X3eLBBNv6vzDfhWj6Gl8EWENmdzA';
  uploadPercent: number = null;
  constructor(
    private auth: AuthService,
    private router: Router,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  onSubmit = (f: NgForm) => {
    const { email, password, username, country, bio, name } = f.form.value;

    this.auth
      .signUp(email, password)
      .then((res) => {
        console.log('Signup auth response: ', res);
        const { uid } = res.user;
        this.db.object(`/users/${uid}`).set({
          id: uid,
          name: name,
          email: email,
          username: username,
          country: country,
          bio: bio,
          picture: this.picture,
        });
      })
      .then(() => {
        this.router.navigateByUrl('/');
        this.toastr.success('SignUp Successfull');
      })
      .catch((err) => {
        this.toastr.error('SighUp Failed');
      });
  };

  uploadFile = async (event) => {
    const file = event.target.files[0];

    let resizedImage = await readAndCompressImage(file, imageConfig);

    const filePath = file.name; // rename the image with TODO: UUID
    const fileRef = this.storage.ref(filePath); // this means whatever name we are giving to the file, we are grabbing that name

    // task to upload the image. NOTE: filePath is where you want to upload the image(i.e. resizedImage)
    const task = this.storage.upload(filePath, resizedImage); // not uploaded yet

    task.percentageChanges().subscribe((percentage) => {
      this.uploadPercent = percentage;
    });

    // after task is completed look for some of the changes happening
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          // file(img) is uploaded already
          fileRef.getDownloadURL().subscribe((url) => {
            // setting uploaded image url for setting the current avatar
            this.picture = url;
            this.toastr.success('Image Upload Successfull');
          });
        })
      )
      .subscribe();
  };
}
